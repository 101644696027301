import Image from "next/image"
import React, { ReactNode, useState } from "react"

import withWindowDimensions from "components/common/withWindowDimensions"
import { LoginProps, withLogin } from "components/Login/withLogin"
import { AccordionData } from "components/LSRevamp/LSRevampInterfaces"

const Divider: React.FC = () => {
  return <div className="h-100 bg-lightgray-1650"></div>
}

interface AccordianProps {
  title: string
  content: string
  position: number
  activeAccordionPosition: number | null
  setActiveAccordionPosition: React.Dispatch<
    React.SetStateAction<number | null>
  >
}

const Accordion: React.FC<AccordianProps> = ({
  title,
  content,
  position,
  activeAccordionPosition,
  setActiveAccordionPosition,
}) => {
  const [isActive, setIsActive] = useState(false)
  const handleActive = () => {
    setIsActive(!isActive)
    setActiveAccordionPosition(position)
  }
  const show = isActive && activeAccordionPosition === position

  return (
    <>
      <div
        className="flex justify-between items-center py-4 gap-3 md:cursor-pointer"
        onClick={handleActive}
      >
        <h6 className="font-bold text-lg">{title}</h6>
        <div className="w-4 shrink-0">
          {show ? (
            <Image
              height={16}
              width={16}
              src= {`${process.env.CDN_URL}/assets/icons/LSRevamp/minus.svg`}
              alt="minus-icon"
            />
          ) : (
            <Image
              height={16}
              width={16}
              src= {`${process.env.CDN_URL}/assets/icons/LSRevamp/plus.svg`}
              alt="plus-icon"
            />
          )}
        </div>
      </div>
      {show ? (
        <div
          className="text-base text-gray-2050 mb-4"
          style={{ whiteSpace: "pre-wrap" }}
        >
          {content}
        </div>
      ) : null}
    </>
  )
}

interface Props {
  isMobileSized?: boolean
  data: AccordionData[]
  buttonText?: string | ReactNode
  hideButton?: boolean
  trackingEvent?: () => void
  faqCtaHandler?: () => void
  bgColor?: string
  title?: string
}

const FAQAccordion: React.FC<Props & LoginProps> = (props) => {
  const { isMobileSized, data, bgColor, title } = props

  const [activeAccordionPosition, setActiveAccordionPosition] = useState<
    number | null
  >(null)

  return (
    <div
      className={
        bgColor
          ? bgColor + "px-5 py-8 md:px-0 md:py-[80px]"
          : "bg-violet-150 px-5 py-8 md:px-8 lg:px-4 md:py-[80px]"
      }
    >
      <div className="flex flex-col items-center md:flex-row-reverse md:max-w-1120 md:m-auto md:justify-between md:items-start">
        <div className="ml-4 lg:ml-60 md:ml-20 md:-m-4 md:mb-7 mt-[-55px] md:mt-[20px]">
          <Image
            src= {`${process.env.CDN_URL}/assets/images/LSRevamp/accordion-img.png`}
            alt="accordion-img"
            width={isMobileSized ? 342 : 456}
            height={isMobileSized ? 330 : 447}
          />
        </div>

        <div className="mt-16 md:mt-0">
          <h4 className="font-bold text-2xl md:text-4xl bg text-center md:text-left mb-4 md:mb-[36px]">
            {title || "Got questions? Find answers here."}
          </h4>
          <div className="md:w-[550px]">
            {data?.map(({ title, content }, i) => {
              return i !== data.length - 1 ? (
                <div key={i}>
                  <Accordion
                    title={title}
                    content={content}
                    activeAccordionPosition={activeAccordionPosition}
                    position={i}
                    setActiveAccordionPosition={setActiveAccordionPosition}
                  />
                  <Divider />
                </div>
              ) : (
                <div key={i}>
                  <Accordion
                    title={title}
                    content={content}
                    activeAccordionPosition={activeAccordionPosition}
                    position={i}
                    setActiveAccordionPosition={setActiveAccordionPosition}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default withWindowDimensions(withLogin(FAQAccordion))
